<template>
  <prototype-attribute-table
    attribute-name="prototype-sub-categories"
    :headers="headers"
    :sort-desc="false" />
</template>

<script>
import PrototypeAttributeTable from '../components/PrototypeAttributeTable.vue'

export default {
  components: {
    PrototypeAttributeTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: 60,
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          width: 200,
          sortable: false
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
